<template>
	<fieldset id="werte">
		<ul class="form">
			<li>
				<bootsprop :label="$t('motor.masseinheit')" :required="true">
					<el-select v-model="boot.masseinheit" style="width: 15ch">
						<el-option
							:label="$t('motor.meter')"
							:value="$t('motor.meter')"
						/>
						<el-option
							:label="$t('motor.fuss')"
							:value="$t('motor.fuss')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.rumpflaenge')" :required="true">
					<input v-model="boot.rumpflaenge" />
				</bootsprop>
				<bootsprop :label="$t('motor.rumpfbreite')">
					<input v-model="boot.rumpfbreite" />
				</bootsprop>
				<bootsprop :label="$t('motor.tiefgang')">
					<input v-model="boot.tiefgang" />
				</bootsprop>
			</li>
			<li>
				<bootsprop
					:label="$t('motor.baumaterial_deck')"
					:required="true"
				>
					<el-select v-model="boot.material_deck">
						<el-option
							:label="$t('motor.gfk')"
							:value="$t('motor.gfk')"
						/>
						<el-option
							:label="$t('motor.stahl')"
							:value="$t('motor.stahl')"
						/>
						<el-option
							:label="$t('motor.aluminium')"
							:value="$t('motor.aluminium')"
						/>
						<el-option
							:label="$t('motor.holz')"
							:value="$t('motor.holz')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop
					:label="$t('motor.baumaterial_rumpf')"
					:required="true"
				>
					<el-select v-model="boot.material_rumpf">
						<el-option
							:label="$t('motor.gfk')"
							:value="$t('motor.gfk')"
						/>
						<el-option
							:label="$t('motor.stahl')"
							:value="$t('motor.stahl')"
						/>
						<el-option
							:label="$t('motor.aluminium')"
							:value="$t('motor.aluminium')"
						/>
						<el-option
							:label="$t('motor.holz')"
							:value="$t('motor.holz')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('segel.decksbelag')">
					<el-select v-model="boot.decksbelag">
						<el-option
							:label="$t('motor.kein_deckbelag')"
							:value="$t('motor.kein_deckbelag')"
						/>
						<el-option
							:label="$t('motor.teak')"
							:value="$t('motor.teak')"
						/>
						<el-option
							:label="$t('motor.korkkunststoff')"
							:value="$t('motor.korkkunststoff')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.rumpffarbe')">
					<el-select v-model="boot.rumpffarbe">
						<el-option
							:label="$t('motor.weiss')"
							:value="$t('motor.weiss')"
						/>
						<el-option
							:label="$t('motor.weiss_blau')"
							:value="$t('motor.weiss_blau')"
						/>
						<el-option
							:label="$t('motor.blau')"
							:value="$t('motor.blau')"
						/>
						<el-option
							:label="$t('motor.grau')"
							:value="$t('motor.grau')"
						/>
						<el-option
							:label="$t('motor.beige')"
							:value="$t('motor.beige')"
						/>
						<el-option
							:label="$t('motor.gelb')"
							:value="$t('motor.gelb')"
						/>
						<el-option
							:label="$t('motor.rot')"
							:value="$t('motor.rot')"
						/>
						<el-option
							:label="$t('motor.orange')"
							:value="$t('motor.orange')"
						/>
						<el-option
							:label="$t('motor.braun')"
							:value="$t('motor.braun')"
						/>
						<el-option
							:label="$t('motor.andere')"
							:value="$t('motor.andere')"
						/>
					</el-select>
				</bootsprop>
			</li>
			<li>
				<bootsprop :label="$t('motor.motorhersteller')">
					<el-select v-model="boot.motorhersteller">
						<el-option
							v-for="(h, index) in motorhersteller"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.motortyp')">
					<el-select v-model="boot.motortyp" style="width: 15ch">
						<el-option
							:label="$t('motor.einbau_diesel')"
							:value="$t('motor.einbau_diesel')"
						/>
						<el-option
							:label="$t('motor.einbau_benzin')"
							:value="$t('motor.einbau_benzin')"
						/>
						<el-option
							:label="$t('motor.außenbordmotor')"
							:value="$t('motor.außenbordmotor')"
						/>
						<el-option
							:label="$t('motor.elektromotor')"
							:value="$t('motor.elektromotor')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop
					:label="$t('motor.motorleistung_ps')"
					:required="true"
				>
					<input v-model="boot.motorleistung_ps" />
				</bootsprop>
				<bootsprop :label="$t('motor.motorleistung_kw')">
					<input v-model="boot.motorleistung_kw" />
				</bootsprop>
			</li>
			<li>
				<bootsprop :label="$t('motor.motorbaujahr')">
					<input v-model="boot.motorbaujahr" />
				</bootsprop>
				<bootsprop :label="$t('motor.betriebsstunden')">
					<input v-model="boot.betriebsstunden" />
				</bootsprop>
				<bootsprop :label="$t('motor.kabinen')">
					<el-select v-model="boot.kabinen">
						<el-option
							v-for="n in 5"
							:key="n"
							:label="n"
							:value="n"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.nutzung')">
					<el-select v-model="boot.nutzung">
						<el-option
							:label="$t('motor.keine_angabe')"
							:value="$t('motor.keine_angabe')"
						/>
						<el-option
							:label="$t('motor.privat')"
							:value="$t('motor.privat')"
						/>
						<el-option
							:label="$t('motor.charter')"
							:value="$t('motor.charter')"
						/>
					</el-select>
				</bootsprop>
			</li>
			<li>
				<bootsprop :label="$t('motor.revier_alt')">
					<el-select v-model="boot.revier_alt">
						<el-option
							:label="$t('motor.ostsee')"
							:value="$t('motor.ostsee')"
						/>
						<el-option
							:label="$t('motor.frankreich')"
							:value="$t('motor.frankreich')"
						/>
						<el-option
							:label="$t('motor.mittelmeer')"
							:value="$t('motor.mittelmeer')"
						/>
						<el-option
							:label="$t('motor.karibik')"
							:value="$t('motor.karibik')"
						/>
						<el-option
							:label="$t('motor.binnen')"
							:value="$t('motor.binnen')"
						/>
						<el-option
							:label="$t('motor.sonstiges')"
							:value="$t('motor.sonstiges')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.revier_neu')">
					<el-select v-model="boot.revier_neu">
						<el-option
							:label="$t('motor.ostsee')"
							:value="$t('motor.ostsee')"
						/>
						<el-option
							:label="$t('motor.frankreich')"
							:value="$t('motor.frankreich')"
						/>
						<el-option
							:label="$t('motor.mittelmeer')"
							:value="$t('motor.mittelmeer')"
						/>
						<el-option
							:label="$t('motor.karibik')"
							:value="$t('motor.karibik')"
						/>
						<el-option
							:label="$t('motor.binnen')"
							:value="$t('motor.binnen')"
						/>
						<el-option
							:label="$t('motor.sonstiges')"
							:value="$t('motor.sonstiges')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.lager')">
					<input v-model="boot.lager" />
				</bootsprop>
				<bootsprop :label="$t('motor.anzahl_voreigner')">
					<el-select v-model="boot.anzahl_voreigner">
						<el-option
							:label="$t('motor.keine')"
							:value="$t('motor.keine')"
						/>
						<el-option :label="1" :value="1" />
						<el-option :label="2" :value="2" />
						<el-option :label="3" :value="3" />
						<el-option :label="4" :value="4" />
						<el-option :label="5" :value="5" />
						<el-option
							:label="$t('motor.mehr_5')"
							:value="$t('motor.mehr_5')"
						/>
					</el-select>
				</bootsprop>
			</li>
			<li>
				<div
					class="input-element"
					style="min-width: 200px; margin-right: 1em"
				>
					<el-checkbox v-model="boot.trailer">
						{{ $t('motor.trailer') }}
					</el-checkbox>
				</div>
				<bootsprop
					v-if="boot.trailer"
					:label="$t('motor.trailer_marke')"
				>
					<input v-model="boot.trailer_marke" />
				</bootsprop>
				<bootsprop
					v-if="boot.trailer"
					:label="$t('motor.trailer_kennz')"
				>
					<input v-model="boot.trailer_kennz" />
				</bootsprop>
				<bootsprop
					v-if="boot.trailer"
					:label="$t('motor.trailer_gewicht')"
				>
					<input v-model="boot.trailer_gewicht" />
				</bootsprop>
			</li>
			<li><hr /></li>
			<li>
				<bootsprop :label="$t('motor.zustand_rumpf')">
					<el-select v-model="boot.zustand_rumpf">
						<el-option
							v-for="(h, index) in zustand"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.zustand_interieur')">
					<el-select v-model="boot.zustand_interieur">
						<el-option
							v-for="(h, index) in zustand"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.zustand_motor')">
					<el-select v-model="boot.zustand_motor">
						<el-option
							v-for="(h, index) in zustand"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
			</li>
			<li><hr /></li>
			<li class="non-flex">
				<h4>{{ $t('motor.wertverbesserung') }}</h4>
			</li>
			<checkprop
				:bprop="{
					label: $t('motor.osmoseschutz'),
					name: 'osmoseschutz',
					value: boot.osmoseschutz,
					alter: boot.osmoseschutz_alter,
					mit_rechnung: true,
					rechnung: boot.osmoseschutz_rechnung,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.motor_neu'),
					name: 'motor_neu',
					value: boot.motor_neu,
					alter: boot.motor_neu_alter,
					mit_rechnung: true,
					rechnung: boot.motor_neu_rechnung,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.teakdeck_neu'),
					name: 'teakdeck_neu',
					value: boot.teakdeck_neu,
					alter: boot.teakdeck_neu_alter,
					mit_rechnung: true,
					rechnung: boot.teakdeck_neu_rechnung,
				}"
				@changed="handlePropChange"
			/>
			<li><hr /></li>
			<li class="non-flex">
				<h4>{{ $t('motor.beschaedigungen') }}</h4>
			</li>
			<li>
				<bootsprop :label="$t('motor.rumpf')">
					<el-select v-model="boot.rumpf_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.deck')">
					<el-select v-model="boot.deck_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.innenraum')">
					<el-select v-model="boot.innenraum_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.motor')">
					<el-select v-model="boot.motor_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
			</li>
			<li>
				<bootsprop :label="$t('motor.getriebe')">
					<el-select v-model="boot.getriebe_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.beschlaege')">
					<el-select v-model="boot.beschlaege_schaden">
						<el-option
							v-for="(h, index) in schadenswerte"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
			</li>
		</ul>
	</fieldset>
</template>

<script>
import { mapGetters } from 'vuex'
import bootsprop from '@/components/elements/bootsprop.vue'
import checkprop from '@/components/elements/checkprop.vue'

export default {
	components: { checkprop, bootsprop },
	methods: {
		handlePropChange(prop) {
			this.boot[prop.name] = prop.value
			const propAlterName = `${prop.name}_alter`
			this.boot[propAlterName] = prop.alter
			const propRechnungName = `${prop.name}_rechnung`
			if (this.boot[propRechnungName])
				this.boot[propRechnungName] = prop.rechnung
		},
	},
	computed: {
		...mapGetters(['boot', 'bootstyp']),
		schadenswerte() {
			return [
				this.$i18n.t('motor.keine'),
				'< 500€',
				'< 1.000€',
				'< 2.000€',
				'< 15.000€',
			]
		},
		zustand() {
			return [
				this.$i18n.t('motor.sehr_gut'),
				this.$i18n.t('motor.gut'),
				this.$i18n.t('motor.befriedigend'),
				this.$i18n.t('motor.ausreichend'),
				this.$i18n.t('motor.mangelhaft'),
			]
		},
		motorhersteller() {
			return [
				this.$i18n.t('allgemein.ka'),
				'Beta-Marine',
				'Bukh',
				'Caterpillar',
				'Craftsman',
				'Cummins',
				'Evinrude',
				'Farymann',
				'Honda',
				'IVECO/Fiat',
				'Johnson',
				'Lombardini',
				'MAN',
				'Mariner',
				'Mercruiser',
				'Mercury',
				'MTU',
				'Nanni',
				'Perkins',
				'Ruggerini',
				'Selva',
				'Sole',
				'Suzuki',
				'Tohatsu',
				'Vetus',
				'Volkswagen',
				'Volvo-Penta',
				'Yamaha',
				'Yanmar',
			]
		},
	},
}
</script>
