<template>
	<header>
		<ul>
			<li class="logo">
				<a
					href="https://ratemyboat.de"
					target="_blank"
					title="zurück zur Hauptseite"
				>
					<img
						:src="require(`@/assets/rmb_logo.svg`)"
						alt="ratemyboat"
					/>
				</a>
			</li>
			<li class="logo">RateMyBoat {{ $t('allgemein.intro') }}</li>
			<li>
				<ul class="lang_select">
					<li>
						<a @click="setLanguage('dk')">
							<img
								class="flag"
								:src="require(`@/assets/dk.svg`)"
							/><span>dansk</span></a
						>
					</li>
					<li>
						<a @click="setLanguage('de')">
							<img
								class="flag"
								:src="require(`@/assets/de.svg`)"
							/><span>deutsch</span></a
						>
					</li>
					<li>
						<a @click="setLanguage('de')">
							<img
								class="flag"
								:src="require(`@/assets/gb.svg`)"
							/><span>english</span></a
						>
					</li>
				</ul>
			</li>
			<li>
				<a href="tel:+4904610011170">+49 (40) 30 77 35 26</a>
			</li>
		</ul>
	</header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
	methods: {
		...mapMutations(['SET_LANGUAGE']),
		setLanguage(l) {
			this.$i18n.locale = l
			this.$store.commit('SET_LANGUAGE', l)
		},
	},
	computed: {
		...mapGetters(['language']),
	},
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"

header
	font-size: 16px
	margin: 0

a
	color: white
	&:hover
		background-color: transparent
ul
	display: flex
	flex-wrap: nowrap;
	justify-content: center
	align-items: center
	padding: 5px
	color: white
	li
		margin: 0 $abstand
		&.logo
			font-size: 24px
			img
				transform: translateY(7px)

.lang_select
	li
		margin: 0 10px
		a
			line-height: 1
			display: block
			text-align: center
			font-size: 12px
			span
				display: block
			img
				height: 16px
</style>
