import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: false,
		language: 'de',
		bilder: [],
		user: {
			email: '',
			notiz: '',
		},
		boot: {
			anzahl_motoren: '',
			anzahl_voreigner: '',
			automodell: '',
			baujahr: '',
			beschlaege_schaden: '',
			betriebsstunden: '',
			deck_schaden: '',
			decksbelag: '',
			getriebe_schaden: '',
			innenraum_schaden: '',
			kabinen: '',
			lager: '',
			leistung_kw: '',
			masseinheit: '',
			material_deck: '',
			material_rumpf: '',
			modell: '',
			motor_neu_alter: '',
			motor_neu_rechnung: '',
			motor_neu: '',
			motor_schaden: '',
			motorbaujahr: '',
			motorhersteller: '',
			motorisierung_alter: '',
			motorisierung: '',
			motorleistung_kw: '',
			motorleistung_ps: '',
			motortyp: '',
			name: '',
			nutzung: '',
			osmoseschutz_alter: '',
			osmoseschutz_rechnung: '',
			osmoseschutz: '',
			revier_alt: '',
			revier_neu: '',
			rumpf_schaden: '',
			rumpfbreite: '',
			rumpffarbe: '',
			rumpflaenge: '',
			rumpfnummer: '',
			teakdeck_neu_alter: '',
			teakdeck_neu_rechnung: '',
			teakdeck_neu: '',
			tiefgang: '',
			trailer_gewicht: '',
			trailer_kennz: '',
			trailer_marke: '',
			trailer: false,
			typ: 'MY',
			zustand_interieur: '',
			zustand_motor: '',
			zustand_rumpf: '',
			// Sonderzubehör
			anker_alter: '',
			anker: '',
			ankerwinsch_alter: '',
			ankerwinsch: '',
			anmerkungen: '',
			audioanlage_alter: '',
			audioanlage: '',
			autopilot_alter: '',
			autopilot: '',
			badeplattform_alter: '',
			badeplattform: '',
			bimini_alter: '',
			bimini: '',
			bugstrahlruder_alter: '',
			bugstrahlruder: '',
			camperverdeck_alter: '',
			camperverdeck: '',
			cockpitkuehlschrank_alter: '',
			cockpitkuehlschrank: '',
			cockpitpolster_alter: '',
			cockpittisch_Sitzgruppe_alter: '',
			cockpittisch_Sitzgruppe: '',
			cockpittisch: '',
			dieselgenerator_alter: '',
			dieselgenerator: '',
			dph_alter: '',
			dph: '',
			echolot_alter: '',
			echolot: '',
			flexiTeek_cockpit_alter: '',
			flexiTeek_cockpit: '',
			flexiTeek_laufdeck_alter: '',
			flexiTeek_laufdeck: '',
			hafenpersenning_alter: '',
			hafenpersenning: '',
			kartenplotter_alter: '',
			kartenplotter: '',
			klimaanlage_alter: '',
			klimaanlage: '',
			kork_alter: '',
			kork: '',
			landstromanschluss_alter: '',
			landstromanschluss: '',
			radar_alter: '',
			radar: '',
			seetoilette_alter: '',
			seetoilette: '',
			sonderfarbe_alter: '',
			sonderfarbe: '',
			sonnenpolster_alter: '',
			sonnenpolster: '',
			sprechfunk_alter: '',
			sprechfunk: '',
			sumlog_alter: '',
			sumlog: '',
			teak_cockpit_alter: '',
			teak_cockpit: '',
			teak_laufdeck_alter: '',
			teak_laufdeck: '',
			warmluftheizung_alter: '',
			warmluftheizung: '',
			wechselrichter_alter: '',
			wechselrichter: '',
			welcher_wert: '',
			welches_foto: '',
			zertifikat_bild: '',
		},
	},
	mutations: {
		SET_LANGUAGE: (state, sprache) => {
			state.language = sprache
		},
		SET_BILDER: (state, pix) => {
			state.bilder = pix
		},
		SET_LOADING: (state, val) => {
			state.loading = val
		},
	},
	actions: {},
	getters: {
		loading: (state) => state.loading,
		language: (state) => state.language,
		user: (state) => state.user,
		boot: (state) => state.boot,
		bilder: (state) => state.bilder,
		bootstyp: (state) => state.boot.typ,
	},
})
