<template>
  <li class="check-line">
    <div class="check-box">
      <el-checkbox v-model="localprop.value">
        {{ localprop.label }}
      </el-checkbox>
    </div>
    <div v-if="localprop.value" class="alter">
      {{ $t("motor.alter") }}
      <el-select v-model="localprop.alter">
        <el-option
          v-for="(a, index) in alterOptions"
          :key="index"
          :label="`${a} ${$t('motor.jahre')}`"
          :value="a"
        />
      </el-select>
    </div>
    <el-checkbox
      v-if="localprop.value && bprop.mit_rechnung"
      v-model="localprop.rechnung"
    >
      {{ $t("motor.rechnung") }}
    </el-checkbox>
  </li>
</template>

<script>
export default {
  props: {
    bprop: {
      type: Object,
      default: () => {
        return {
          name: "",
          value: "",
          label: "",
          alter: "< 5",
          mit_rechnung: false,
          rechnung: false,
        };
      },
    },
  },
  data() {
    return {
      alterOptions: ["< 5", "< 10", "< 15", "< 20", ">= 20"],
      localprop: {
        name: "",
        value: "",
        label: "",
        alter: "< 5",
        mit_rechnung: false,
        rechnung: false,
      },
    };
  },
  watch: {
    "bprop.label": {
      immediate: true,
      handler() {
        this.assignToLocal();
      },
    },
    "localprop.value"() {
      this.emitData();
    },
    "localprop.alter"() {
      this.emitData();
    },
    "localprop.rechnung"() {
      this.emitData();
    },
  },
  methods: {
    emitData() {
      this.$emit("changed", this.localprop);
    },
    assignToLocal() {
      this.localprop = Object.assign({}, this.bprop);
    },
  },
};
</script>

<style lang="sass">
@import "@/globals.sass"
div.check-box
	display: block
	width: 300px
	height: 40px
div.alter
	margin: 0 1em 0 2em
	transform: translateY(-7px)
</style>
