<template>
	<fieldset id="bilder">
		<ul class="form">
			<li class="non-flex">
				<h4>{{ $t('allgemein.bilder') }} <sup>*</sup></h4>
				<label v-html="$t('allgemein.upload_mod1')" />
				<VueFileAgent
					ref="vueFileAgent"
					class="vfa-bckgrd"
					v-model="fileRecords"
					:upload-url="uploadUrl"
					:multiple="true"
					:deletable="false"
					:meta="true"
					:accept="'image/*'"
					:max-size="'8MB'"
					:help-text="`${$t('allgemein.choose_file')}`"
					:error-text="{
						type: 'Invalid file type. Only images allowed',
						size: 'Files should not exceed 8MB in size',
					}"
					@upload="onUpload($event)"
				/>
			</li>
			<li v-if="okBilder.length > 0">
				<bootsprop :label="$t('allgemein.upload_mod2')">
					<el-select v-model="boot.zertifikat_bild">
						<el-option
							v-for="(file, index) in okBilder"
							:key="index"
							:label="file"
							:value="file"
						/>
					</el-select>
				</bootsprop>
			</li>
		</ul>
	</fieldset>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import bootsprop from '@/components/elements/bootsprop.vue'

export default {
	components: { bootsprop },
	methods: {
		...mapMutations(['SET_BILDER']),
		onUpload(res) {
			const fName = res[0].data.upload_data.file_name
			this.fileNames.push(fName)
			this.$store.commit('SET_BILDER', this.fileNames)
		},
	},
	computed: {
		...mapGetters(['boot', 'bootstyp']),
		uploadUrl() {
			return `${process.env.VUE_APP_API_URL}Bewertungen/upload`
		},
		okBilder() {
			if (!this.fileRecords) return []
			let arr = []
			for (let pic of this.fileRecords) {
				if (pic?.upload?.data?.upload_data?.client_name) {
					arr.push(pic?.upload?.data?.upload_data?.client_name)
				}
			}
			return arr
		},
	},
	data() {
		return {
			fileRecords: [],
			fileNames: [],
			uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
		}
	},
}
</script>

<style lang="sass">
@import "@/globals.sass"
.vfa-bckgrd
	background-size: contain
	background-position: center center
	background-repeat: no-repeat
	background-image: url(~@/assets/cm09_bilder_backgrd_2.jpg)
	background-color: white
	.help-text
		color: $rot !important
		transform: translateY(40px)
	.file-preview
		svg
			transform: translateY(-30px)
.vue-file-agent,
.file-preview-new:before
	background: transparent !important
</style>
