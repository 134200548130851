<template>
	<section>
		<main class="danke">
			<h2>{{ $i18n.t('allgemein.danke_hed') }}</h2>
			<p>{{ $i18n.t('allgemein.danke_body') }}</p>
			<p class="back">
				<el-button type="primary" @click="goback">
					zurück zur Hauptseite
				</el-button>
			</p>
		</main>
	</section>
</template>

<script>
export default {
	methods: {
		goback() {
			document.location.href = 'https://ratemyboat.de/'
		},
	},
	data() {
		return {
			form_sent: false,
			email: '',
			fileRecords: [],
		}
	},
}
</script>

<style lang="sass">
@import "@/globals.sass"

main.danke
	margin: 0
	padding: $abstand*4 $abstand*2
	box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 1)
	background-color: $whitea
	border-radius: 10px
	overflow: hidden
	p,h2
		color: $blau
	.back
		padding: $abstand
		text-align: center
		.el-button--primary
			background-color: $blau
			border-color: $blau
</style>
