import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.filter('preis', (s) => {
	if (!window.Intl) return s
	return new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	}).format(s)
})

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const deLocale = require('./locales/de.json')
const enLocale = require('./locales/en.json')
const dkLocale = require('./locales/dk.json')
import deElementLocale from 'element-ui/lib/locale/lang/de'
import enElementLocale from 'element-ui/lib/locale/lang/en'
const messages = {
	en: {
		...enLocale,
		...enElementLocale,
	},
	de: {
		...deLocale,
		...deElementLocale,
	},
	dk: {
		...dkLocale,
		...enElementLocale,
	},
}
const i18n = new VueI18n({
	locale: 'de',
	fallbackLocale: 'de',
	messages,
})

import VueFileAgent from 'vue-file-agent'
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
Vue.use(VueFileAgent)

// element ui
Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value),
})

new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app')
