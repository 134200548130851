import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/bewertung.vue'
import Danke from '@/views/danke.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/danke',
		name: 'Danke',
		component: Danke,
	},
	{
		path: '/',
		name: 'Rating',
		component: Home,
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
