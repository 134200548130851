<template>
	<div id="app">
		<hed />
		<router-view></router-view>
		<disclaimer v-if="$route.name == 'Rating'" />
	</div>
</template>

<script>
import hed from '@/components/hed.vue'
import disclaimer from '@/components/disclaimer.vue'
export default {
	components: { hed, disclaimer },
}
</script>

<style lang="sass">
@import "~marx-css/css/marx.css"
@import "@/globals.sass"
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap')

*
	margin: 0

html
	scroll-behavior: smooth

body
	overflow-x: hidden
	margin: 0
	padding: 0
	hyphens: auto
	font-family: $family
	background-attachment: fixed
	background-repeat: no-repeat
	background-size: cover
	background-image: url(https://r8mb.contentplanr.com/wp-content/uploads/2021/11/birds-eye-view-g9965712ea_1920.jpeg)
ul,ol
	list-style: none
	margin: 0
	padding: 0
	li
		margin: 0
		padding: 0

fieldset
	padding: 0
	border-bottom: 1px solid rgb(177, 206, 217)
	width: 100%

a
	cursor: pointer
	text-decoration: none
	color: $blau
	&:visited, &:focus
		cursor: pointer
		text-decoration: none
		color: $blau
	&:hover
		cursor: pointer
		text-decoration: none
		color: white
		background-color: $blau
		span
			color: white

p
	font-size: 14px
	line-height: 150%
</style>
