<template>
	<fieldset id="user">
		<ul class="form">
			<li class="non-flex">
				<bootsprop label="Email" :required="true">
					<input v-model="user.email" type="email" />
				</bootsprop>
			</li>
			<li class="non-flex">
				<bootsprop :label="$t('motor.anmerkungen')">
					<el-input
						v-model="user.notiz"
						type="textarea"
						:autosize="{ minRows: 2, maxRows: 10 }"
					/>
				</bootsprop>
			</li>
		</ul>
	</fieldset>
</template>

<script>
import { mapGetters } from 'vuex'
import bootsprop from '@/components/elements/bootsprop.vue'

export default {
	components: { bootsprop },
	computed: {
		...mapGetters(['boot', 'user', 'bootstyp']),
	},
}
</script>
