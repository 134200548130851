<template>
	<fieldset id="bootstyp">
		<ul class="form">
			<autoinput @selected="setModell" />
			<li style="align-items: flex-start">
				<bootsprop :label="$t('motor.bootstyp')" :required="true">
					<input v-model="boot.modell" />
				</bootsprop>
				<bootsprop :label="$t('motor.baujahr')" :required="true">
					<input v-model="boot.baujahr" />
				</bootsprop>
				<el-switch
					v-model="boot.typ"
					class="type-switch"
					:active-text="$t('allgemein.motorboot')"
					:inactive-text="$t('allgemein.segelboot')"
					active-color="rgb(177, 206, 217)"
					inactive-color="rgb(177, 206, 217)"
					active-value="MY"
					inactive-value="SY"
				/>
			</li>
			<li>
				<bootsprop :label="$t('motor.name')" :required="true">
					<input v-model="boot.name" />
				</bootsprop>
				<bootsprop :label="$t('motor.rumpfnummer')" :required="true">
					<input v-model="boot.rumpfnummer" />
				</bootsprop>
			</li>
		</ul>
	</fieldset>
</template>

<script>
import { mapGetters } from 'vuex'
import autoinput from '@/components/elements/autoinput.vue'
import bootsprop from '@/components/elements/bootsprop.vue'

export default {
	components: { bootsprop, autoinput },
	methods: {
		setModell(item) {
			this.boot.modell = item.name
			this.boot.automodell = item.name
		},
		handlePropChange(prop) {
			this.boot[prop.name] = prop.value
			const propAlterName = `${prop.name}_alter`
			this.boot[propAlterName] = prop.alter
			const propRechnungName = `${prop.name}_rechnung`
			if (this.boot[propRechnungName])
				this.boot[propRechnungName] = prop.rechnung
		},
	},
	computed: {
		...mapGetters(['boot', 'bootstyp']),
	},
	data() {
		return {
			rnr_exists: false,
		}
	},
}
</script>
