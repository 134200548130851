<template>
	<div class="disclaimer">
		<div>
			<h5>Disclaimer</h5>
			<template v-if="language == 'dk'">
				<ol>
					<li>
						Eftersom oplysningerne for værdiansættelsen bliver
						oplyst fra forbrugeren, kan certifikatet ikke indeholde
						oplysninger om fartøjets objektive tilstand, dets
						tilbehør og funktioner. Dette kan kun vurderes ved en
						fysisk vurdering.
					</li>
					<li>
						HIB Hanse GmbH er ikke ansvarlig for værdiansættelsen,
						der er fastlagt i certifikatet.
					</li>
					<li>
						Den af HIB Hanse GmbH fastlagte vurdering for det valgte
						fartøj, er baseret på brugerens egne oplysninger.
						Evalueringen er baseret ud fra
						Hanse-evalueringssystemer, der er beregnet middelværdier
						opstået efter udligning af regionale forskelle. De er
						kun gyldige for markedssituationen, der blev bestemt på
						tidspunktet for værdiansættelsen.
					</li>
					<li>
						Online værdiansættelse via www.ratemyboat.de er ikke
						mulig for både over 35 år, der kræver en fysisk
						vurdering for, at man kan vurdere fartøjets tilstand.
						Vurderingen foretages eksternt efter aftale. Kontakt HIB
						Hanse GmbH for et uforpligtende tilbud:
						<a href="mailto:sb@sportboot-gutachter.de"
							>sb@sportboot-gutachter.de</a
						>
					</li>
				</ol>
			</template>
			<template v-if="language == 'de'">
				<ol>
					<li>
						Da die Wertermittlung auf den Angaben des Nutzers
						basiert, kann das Zertifikat keine Aussage über den
						objektiven Zustand der Yacht, ihres Zubehörs und der
						Funktionalitäten enthalten. Dies kann nur durch eine
						sachverständige Begutachtung ermittelt werden.
					</li>
					<li>
						HIB Hanse GmbH haftet nicht für die Durchsetzbarkeit der
						in einer Sportbootbewertung ermittelten Werte.
					</li>
					<li>
						Die von HIB Hanse GmbH ermittelte Bewertung für das vom
						Nutzer gewählte Sportboot richtet sich nach den Angaben
						des Nutzers. Grundlage der Bewertung sind die jeweils
						aktuellen Hanse-Bewertungssysteme. Sie stellen
						Mittelwerte dar, die sich nach Ausgleich regionaler
						Unterschiede ergeben und nur Gültigkeit für die zum
						Zeitpunkt der Bewertung ermittelte Marktlage haben.
					</li>
					<li>
						Bei Booten ab einem Alter von 35 Jahren ist eine
						online-Bewertung über www.ratemyboat.de nicht möglich.
						Für diese Boote ist zur richtigen Erfassung des Zustands
						eine Besichtigung erforderlich. Diese Besichtigung kann
						im Remote-Verfahren oder durch einen Ortstermin
						erfolgen. Gerne unterbreiten wir hierfür ein
						individuelles Angebot:
						<a href="mailto:sb@sportboot-gutachter.de"
							>sb@sportboot-gutachter.de</a
						>
					</li>
				</ol>
			</template>
			<h5>ratemyboat by<br />HIB Hanse Ingenieurbüro GmbH</h5>
			<p>Steendammswisch 20 a<br />22459 Hamburg</p>
			<p>Tel.: (040) 30 77 35 26</p>
			<p>
				<a href="mailto:kontakt@ratemyboat.de">kontakt@ratemyboat.de</a>
			</p>
			<p><a href="ratemyboat.de">ratemyboat.de</a></p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters(['language']),
	},
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"

.disclaimer
	display: flex
	flex-wrap: nowrap
	flex-direction: row
	justify-content: center
	div
		background-color: rgba(0, 0, 0, 0.6)
		border-radius: 10px
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
		backdrop-filter: blur(5px)
		-webkit-backdrop-filter: blur(5px)
		border: 1px solid rgba(255, 255, 255, 0.3)
		margin: $abstand*2 0
		padding: $abstand
		max-width: 60ch
		h5
			margin-bottom: $abstand
			color: white
		p
			color: white
			margin: 0
		ol
			list-style: numbers
			margin-bottom: 4em
			li
				margin-bottom: $abstand/2
				color: white
		a
			color: white
</style>
