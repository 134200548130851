import axios from 'axios'
import store from '@/store/index.js'
const baseURL = process.env.VUE_APP_API_URL

export async function request(url = '', data = null) {
	let config = {
		method: 'post',
		url: `${baseURL}${url}`,
		data: data,
		responseType: 'json',
	}

	store.commit('SET_LOADING', true)
	try {
		const response = await axios(config)
		return response.data
	} catch (err) {
		return false
	} finally {
		store.commit('SET_LOADING', false)
	}
}
