<template>
	<div class="input-element">
		<label>
			<span>{{ label }}<sup v-if="required">*</sup></span>
		</label>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"
div.input-element
	margin-right: 1em
	min-width: 150px
	label
		display: block
		color: $blau
		margin: 0
		padding: 0
		position: relative
		line-height: 1.4
		font-size: 12px
		span
			display: inline-block
			position: relative
		sup
			position: absolute
			top: -10px
			color: #c60c30
			font-size: 24px
</style>
