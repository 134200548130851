<template>
	<fieldset id="zubehoer">
		<ul class="form">
			<li class="non-flex">
				<h4>{{ $t('motor.sonderausstattung') }}</h4>
			</li>
			<li class="non-flex">
				<p>
					<strong>{{ $t('motor.hinweis') }}</strong>
				</p>
				<p>{{ $t('motor.hinweis_text') }}</p>
				<ol class="beispiele">
					<li>{{ $t('motor.steuerkompass') }}</li>
					<li>{{ $t('motor.kuehleinrichtung') }}</li>
					<li>{{ $t('motor.herd') }}</li>
					<li>{{ $t('motor.tanks') }}</li>
					<li>{{ $t('motor.polster') }}</li>
					<li>{{ $t('motor.beleuchtung') }}</li>
				</ol>
				<p>{{ $t('motor.zubehoer_text') }}</p>
				<p>
					<strong>{{ $t('motor.zubehoer_text2') }}</strong>
				</p>
			</li>
			<li style="margin-bottom: 2em">
				<bootsprop :label="$t('motor.motorisierung')">
					<el-select v-model="boot.motorisierung">
						<el-option
							:label="$t('motor.motorisierung_standard')"
							:value="$t('motor.motorisierung_standard')"
						/>
						<el-option
							:label="$t('motor.motorisierung_mehr')"
							:value="$t('motor.motorisierung_mehr')"
						/>
						<el-option
							:label="$t('motor.motorisierung_aussen')"
							:value="$t('motor.motorisierung_aussen')"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.anzahl_motoren')">
					<el-select v-model="boot.anzahl_motoren">
						<el-option
							v-for="(h, index) in 4"
							:key="index"
							:label="h"
							:value="h"
						/>
					</el-select>
				</bootsprop>
				<bootsprop :label="$t('motor.leistung_kw')">
					<input v-model="boot.leistung_kw" />
				</bootsprop>
				<bootsprop :label="$t('motor.alter')">
					<el-select v-model="boot.motorisierung_alter">
						<el-option
							v-for="(h, index) in alterOptions"
							:key="index"
							:label="`${h} ${$t('motor.jahre')}`"
							:value="h"
						/>
					</el-select>
				</bootsprop>
			</li>
			<checkprop
				:bprop="{
					label: $t('motor.dph'),
					name: 'dph',
					value: boot.dph,
					alter: boot.dph_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.sonderfarbe'),
					name: 'sonderfarbe',
					value: boot.sonderfarbe,
					alter: boot.sonderfarbe_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.bugstrahlruder'),
					name: 'bugstrahlruder',
					value: boot.bugstrahlruder,
					alter: boot.bugstrahlruder_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.anker'),
					name: 'anker',
					value: boot.anker,
					alter: boot.anker_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.ankerwinsch'),
					name: 'ankerwinsch',
					value: boot.ankerwinsch,
					alter: boot.ankerwinsch_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.teak_cockpit'),
					name: 'teak_cockpit',
					value: boot.teak_cockpit,
					alter: boot.teak_cockpit_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.teak_laufdeck'),
					name: 'teak_laufdeck',
					value: boot.teak_laufdeck,
					alter: boot.teak_laufdeck_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.flexiTeek_cockpit'),
					name: 'flexiTeek_cockpit',
					value: boot.flexiTeek_cockpit,
					alter: boot.flexiTeek_cockpit_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.flexiTeek_laufdeck'),
					name: 'flexiTeek_laufdeck',
					value: boot.flexiTeek_laufdeck,
					alter: boot.flexiTeek_laufdeck_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.kork'),
					name: 'kork',
					value: boot.kork,
					alter: boot.kork_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.sonnenpolster'),
					name: 'sonnenpolster',
					value: boot.sonnenpolster,
					alter: boot.sonnenpolster_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.bimini'),
					name: 'bimini',
					value: boot.bimini,
					alter: boot.bimini_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.camperverdeck'),
					name: 'camperverdeck',
					value: boot.camperverdeck,
					alter: boot.camperverdeck_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.hafenpersenning'),
					name: 'hafenpersenning',
					value: boot.hafenpersenning,
					alter: boot.hafenpersenning_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.cockpittisch_Sitzgruppe'),
					name: 'cockpittisch_Sitzgruppe',
					value: boot.cockpittisch_Sitzgruppe,
					alter: boot.cockpittisch_Sitzgruppe_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.cockpittisch'),
					name: 'cockpittisch',
					value: boot.cockpittisch,
					alter: boot.cockpittisch_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.cockpitkuehlschrank'),
					name: 'cockpitkuehlschrank',
					value: boot.cockpitkuehlschrank,
					alter: boot.cockpitkuehlschrank_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.badeplattform'),
					name: 'badeplattform',
					value: boot.badeplattform,
					alter: boot.badeplattform_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.seetoilette'),
					name: 'seetoilette',
					value: boot.seetoilette,
					alter: boot.seetoilette_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.warmluftheizung'),
					name: 'warmluftheizung',
					value: boot.warmluftheizung,
					alter: boot.warmluftheizung_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.klimaanlage'),
					name: 'klimaanlage',
					value: boot.klimaanlage,
					alter: boot.klimaanlage_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.dieselgenerator'),
					name: 'dieselgenerator',
					value: boot.dieselgenerator,
					alter: boot.dieselgenerator_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.landstromanschluss'),
					name: 'landstromanschluss',
					value: boot.landstromanschluss,
					alter: boot.landstromanschluss_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.wechselrichter'),
					name: 'wechselrichter',
					value: boot.wechselrichter,
					alter: boot.wechselrichter_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.audioanlage'),
					name: 'audioanlage',
					value: boot.audioanlage,
					alter: boot.audioanlage_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.sprechfunk'),
					name: 'sprechfunk',
					value: boot.sprechfunk,
					alter: boot.sprechfunk_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.kartenplotter'),
					name: 'kartenplotter',
					value: boot.kartenplotter,
					alter: boot.kartenplotter_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.autopilot'),
					name: 'autopilot',
					value: boot.autopilot,
					alter: boot.autopilot_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.radar'),
					name: 'radar',
					value: boot.radar,
					alter: boot.radar_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.echolot'),
					name: 'echolot',
					value: boot.echolot,
					alter: boot.echolot_alter,
				}"
				@changed="handlePropChange"
			/>
			<checkprop
				:bprop="{
					label: $t('motor.sumlog'),
					name: 'sumlog',
					value: boot.sumlog,
					alter: boot.sumlog_alter,
				}"
				@changed="handlePropChange"
			/>
		</ul>
	</fieldset>
</template>

<script>
import { mapGetters } from 'vuex'
import bootsprop from '@/components/elements/bootsprop.vue'
import checkprop from '@/components/elements/checkprop.vue'

export default {
	components: { checkprop, bootsprop },
	methods: {
		handlePropChange(prop) {
			this.boot[prop.name] = prop.value
			const propAlterName = `${prop.name}_alter`
			this.boot[propAlterName] = prop.alter
			const propRechnungName = `${prop.name}_rechnung`
			if (this.boot[propRechnungName])
				this.boot[propRechnungName] = prop.rechnung
		},
	},
	computed: {
		...mapGetters(['boot', 'bootstyp']),
	},
	data() {
		return {
			alterOptions: ['< 5', '< 10', '< 15', '< 20', '>= 20'],
		}
	},
}
</script>

<style lang="sass">
@import "@/globals.sass"
ol.beispiele
	display: flex
	flex-wrap: wrap
	justify-content: center
	li
		margin: 0 .5em !important
</style>
