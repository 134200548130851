<template>
	<li class="non-flex auto-input">
		<h4>
			{{ $t('allgemein.bootstyp_suchen') }}
			<sup>{{ info }}</sup>
		</h4>
		<input
			v-model="eingabe"
			type="text"
			debounce="300"
			role="textbox"
			:placeholder="$t('allgemein.mindestens_drei')"
		/>
		<spinner v-if="loading" />
		<div v-if="modelle.length > 0 && !selected">
			<ol class="modelle">
				<li v-for="(m, i) in modelle" :key="i">
					<a @click="select(m)"
						><span>{{ m.werft }}:</span> {{ m.name }}</a
					>
				</li>
			</ol>
		</div>
	</li>
</template>

<script>
import api from '@/api/bewertung'
import spinner from '@/components/elements/spinner'
export default {
	components: { spinner },
	watch: {
		eingabe: {
			handler: 'getModelle',
		},
	},
	methods: {
		async getModelle() {
			if (this.eingabe.length > 1) {
				this.loading = true
				const r = await api.getModelle(this.eingabe)
				this.modelle = r
				if (r.length > 0) {
					this.info = `${r.length} Modelle gefunden`
				} else {
					this.info = `keine Modelle gefunden, bitte geben sie den Bootstyp manuell ein`
				}
				this.loading = false
			}
		},
		select(item) {
			this.selected = true
			this.eingabe = ''
			this.$emit('selected', item)
		},
	},
	data() {
		return {
			loading: false,
			selected: false,
			eingabe: '',
			info: '',
			modelle: [],
		}
	},
}
</script>

<style lang="sass" scoped>
@import "@/globals.sass"
h4
	font-size: 16px
sup
	display: inline-block
	transform: translateY(7px)
	color: $grau
	margin-left: .5em
	font-size: 14px
li.auto-input
	position: relative
	margin-bottom: $abstand
	ol
		position: absolute
		z-index: +1
		top: 74px
		left: 0
		max-height: 300px
		width: auto
		background-color: white
		overflow-y: scroll
		border: 1px solif $grau
		box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
		li
			margin: 0
			padding: 0
			display: block
			a
				display: block
				padding: 0 20px
				background-color: white
				&:hover
					background-color: $blau
					color: white
					span
						color: white
</style>
