import { request } from '@/api/common.js'

export default {
	async getModelle(str) {
		return await request('Boote/getModelle', { string: str })
	},
	async saveAnfrage(payload) {
		return await request('Bewertungen/send', payload)
	},
	async createPayment(payload) {
		return await request('Checkout/createPayment', payload)
	},
}
