<template>
	<section>
		<nav>
			<ul>
				<li><a href="#bootstyp">Bootstyp</a></li>
				<li><a href="#werte">Werte</a></li>
				<li><a href="#zubehoer">Sonderausstattung</a></li>
				<li><a href="#bilder">Bilder</a></li>
			</ul>
			<p class="hinweis">Bitte machen Sie mindestens diese Angaben:</p>
			<ol class="field-check">
				<li v-for="(field, index) in requiredFields" :key="index">
					<span
						class="check-label"
						:class="{ done: boot[field.fname] != '' }"
					></span>
					{{ field.label }}
				</li>
				<li>
					<span
						class="check-label"
						:class="{ done: bildIsDone }"
					></span>
					Bilder
				</li>
				<li>
					<span
						class="check-label"
						:class="{ done: emailIsDone }"
					></span>
					Email-Adresse
				</li>
			</ol>
		</nav>
		<main>
			<bootstyp />
			<wert />
			<sonder />
			<bilder />
			<user />
			<fieldset id="user">
				<ul class="form">
					<li class="send">
						<el-button
							type="primary"
							@click="saveAnfrage"
							:disabled="disabled"
						>
							{{ buttonText }}</el-button
						>
					</li>
				</ul>
			</fieldset>
		</main>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/bewertung'
import bootstyp from '@/components/bootstyp.vue'
import wert from '@/components/wert.vue'
import sonder from '@/components/sonder.vue'
import bilder from '@/components/bilder.vue'
import user from '@/components/user.vue'

export default {
	components: { bootstyp, wert, sonder, bilder, user },
	methods: {
		async saveAnfrage() {
			const r = await api.saveAnfrage({
				boot: this.boot,
				user: this.user,
				bilder: this.bilder,
			})
			console.log('🚀 ~ saveAnfrage ~ r', r)
			if (r) {
				const payment_data = {
					rating_id: r,
					rating_type: this.bootstypString,
					rating_price: this.preis,
				}
				const url = await api.createPayment(payment_data)
				if (url) {
					window.location.href = url
				} else {
					alert('Payment-Prozess konnte nicht gestartet werden!')
				}
			} else {
				alert('Anfrage konnte nicht gespeichert werden!')
			}
		},
	},
	computed: {
		...mapGetters(['boot', 'user', 'bilder', 'bootstyp']),
		bildIsDone() {
			if (this.bilder.length > 0) return true
			return false
		},
		emailIsDone() {
			if (this.user.email) return true
			return false
		},
		disabled() {
			let requiredProps = true
			for (let field of this.requiredFields) {
				if (!this.boot[field.fname]) {
					requiredProps = false
					break
				}
			}
			if (requiredProps && this.bildIsDone && this.emailIsDone)
				return false
			return true
		},
		laenge() {
			return Number.parseInt(this.boot.rumpflaenge)
		},
		preis() {
			let preis = '49.00' // bis 8m
			if (this.laenge >= 12) {
				preis = '99.00'
			} else if (this.laenge >= 10) {
				preis = '79.00'
			} else if (this.laenge >= 8) {
				preis = '69.00'
			}
			return preis
		},
		bootstypString() {
			return this.bootstyp === 'MY' ? 'Motorboot' : 'Segelboot'
		},
		buttonText() {
			if (this.disabled) return 'Bitte vervollständigen Sie Ihre Angaben'
			return `${this.bootstypString}-Zertifikat für ${this.preis} € anfordern`
		},
	},
	data() {
		return {
			requiredFields: [
				{ fname: 'modell', label: 'Bootstyp' },
				{ fname: 'baujahr', label: 'Baujahr' },
				{ fname: 'name', label: 'Bootsname' },
				{ fname: 'rumpfnummer', label: 'Rumpfnummer' },
				{ fname: 'masseinheit', label: 'Masseinheit' },
				{ fname: 'rumpflaenge', label: 'Rumpflänge' },
				{ fname: 'material_deck', label: 'Material Deck' },
				{ fname: 'material_rumpf', label: 'Material Rumpf' },
				{ fname: 'motorleistung_ps', label: 'Motorleistung PS' },
			],
		}
	},
}
</script>

<style lang="sass">
@import "@/globals.sass"

section
	padding: 2em 0
	display: flex;
	flex-wrap: nowrap;
	justify-content: center

nav
	display: block
	position: -webkit-sticky
	position: sticky
	top: 0
	width: 150px
	height: 600px
	border-top-left-radius: 10px
	border-bottom-left-radius: 10px
	padding-top: $abstand
	background-color: $whitea
	backdrop-filter: blur(5px)
	-webkit-backdrop-filter: blur(5px)
	font-size: 14px
	a
		display: block
		color: $text
		padding: 5px 10px
		&:visited
			color: $text
		&:hover
			color: white

p.hinweis
	color: $grau
	margin: $abstand 0 0 0
	padding: 5px 10px
ol.field-check
	li
		margin: 0
		color: $grau
		padding: 2px 10px
span.check-label
	display: inline-block
	transform: translateY(4px)
	width: 16px
	height: 16px
	background-size: 16px
	background-position: left top
	background-repeat: no-repeat
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17 5H7a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2zm-1 8H8v-2h8z' fill='%23C60C30'/%3E%3C/svg%3E")
	&.done
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='m10.933 13.519-2.226-2.226-1.414 1.414 3.774 3.774 5.702-6.84-1.538-1.282z' fill='green'/%3E%3Cpath d='M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z' fill='green'/%3E%3C/svg%3E")

main
	margin: 0
	padding: 0
	min-width: 800px
	box-shadow: 10px 10px 15px 0 rgba(0, 0, 0, 1)
	border-top-right-radius: 10px
	border-bottom-right-radius: 10px
	border-bottom-left-radius: 10px
	overflow: hidden
	backdrop-filter: blur(5px)
	-webkit-backdrop-filter: blur(5px)

ul.form
	font-size: 14px
	background-color: $whitea
	padding: 1rem 2rem
	margin: 0
	width: 100%
	li
		margin-bottom: 4px
		display: flex
		flex-wrap: wrap
		justify-content: flex-start
		align-items: flex-end
		h4
			font-size: 16px
			color: $blau
		&.non-flex
			display: block
		&.check-line
			.el-checkbox__label
	li.send
		display: block
		text-align: center
		.el-button--primary
			background-color: $blau
			border-color: $blau

ul li.bottone
    margin: 3em
    display: block
    text-align: center
p
	color: #c60c30

ol.beispiele
	margin: 0 0 2em 2em
	list-style: disc
	li
		margin: 0
		padding: 0
		font-size: 14px

ol.modelle
	margin: 0 0 2em 0
	padding: 0
	display: block
	max-height: 200px
	overflow-y: scroll
	overflow-x: hidden
	li
		a
			margin-bottom: .5em
			cursor: pointer
			span
				color: $grau

.type-switch
	transform: translateY(40px)

span.el-switch__label
	color: $grau
.el-checkbox__input.is-checked+.el-checkbox__label
	color: $blau !important
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner
	border-color: $blau !important
	background-color: $blau !important

h4 sup
	color: $rot
	font-size: 16px


@media (min-width: 940px)
	ul.form
		li
			flex-wrap: nowrap
		li.send
			display: block
			text-align: center

#loading
	text-align: center
	position: relative
	height: 80px
	&:after
		content: ''
		position: absolute
		top: 0
		left: calc(50% - 25px)
		display: block
		width: 50px
		height: 50px
		border: 3px solid $blaua
		border-radius: 50%
		border-top-color: $blau
		animation: spin 1s ease-in-out infinite

@keyframes spin
	to
		transform: rotate(360deg)
</style>
